import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NewsSectionBlock from '../components/NewsSectionBlock'
import SEO from '../components/seo'
import Typography from '../components/Common/Typography'
import List from '../components/Common/List'

import * as s from '../pages/news.module.scss'

const SimpleConstructionSiteSafetyRules: React.FC = () => {
  return (
    <>
      <SEO
        title="General safety rules at the construction site"
        description="Construction sites are considered one of the most dangerous places to work. Follow these simple construction site safety rules to keep yourself and others safe."
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          Simple construction site safety rules
        </Typography>
        <Typography variant="body1">
          Technological advancements have made construction projects less
          dangerous than in previous days. But does that mean a worker
          associated with this industry will experience no injuries on site?
          Unfortunately, accidents are inevitable no matter how technological
          improvements have improved the sector to reduce injury rates. That
          brings the discussion of the safety measures required in construction
          sites, whether large or small.
        </Typography>
        <Typography variant="body1">
          Construction sites are the most dangerous areas to work in. A
          construction worker has more likelihood of encountering physical
          injuries than other professionals. Thus, every construction company or
          project manager must prioritise the significance of safety rules.
          Besides protecting workers’ health, it keeps the public secure.
        </Typography>
        <Typography variant="body1">
          Failure to keep up with proper procedures puts workers and the public
          at risk. So, safety measures and protocols adhere to industry
          regulations. In addition to offering credibility, it mitigates the
          risks of employee injuries. Below are the simple safety rules to keep
          your workers and the public safe during the job.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/articles/simple-construction-site-safety-rules/image-1.jpeg"
            alt="Simple construction site safety rules"
            title="Simple construction site safety rules"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          #1 Induction and PPE
        </Typography>
        <Typography variant="body1">
          As a fresher in this industry, the construction worker must not start
          their work without an induction. Now, what is induction? Simply put,
          inductions are the legal prerequisite for each construction location
          to work on. It makes you familiar with the following things:
        </Typography>
        <List className="pl-4">
          <li>Where you must sign in</li>
          <li>Where should you visit</li>
          <li>What you should do</li>
          <li>What to avoid</li>
        </List>
        <Typography variant="body1">
          In short, a site induction or contractor induction is the form of
          introductory guidelines. It ensures new workers become familiar with
          the organisational layout, workplace culture, and responsibilities.
        </Typography>
        <Typography variant="body1">
          To avoid potential hazards at the construction site, one should always
          wear a protective outfit from day one. That calls for PPE. Personal
          protective equipment is the safest defence for construction workers.
          Basic PPE equipment types include:
        </Typography>
        <List className="pl-4">
          <li>Hard hats to keep your head protected</li>
          <li>Feet protection equipment made of steel</li>
          <li>
            Full-length pants and shirts that will protect against cuts, burns,
            scrapes, and more
          </li>
          <li>
            Hand protection gear like welding, insulated, or rubber gloves
          </li>
          <li>
            Ear protective gear to manage noises of jackhammers and chainsaws
          </li>
          <li>
            Eye and face preventive accessories to perform activities such as
            welding, to grind, nailing, and cutting
          </li>
        </List>
        <Typography variant="h2" color="blue">
          #2 Keep the Area Clean
        </Typography>
        <Typography variant="body1">
          Several works associated with construction can contribute to a messy
          workplace. An unorganised workplace (especially in a construction
          site) means workers may experience slips and falls every now and then.
          One should consider following these tips to keep the site neat and
          tidy to avoid such accidents:
        </Typography>
        <List className="pl-4">
          <li>De-cluttering the place by cleaning the debris</li>
          <li>Stack plywood, leaning lumber, and other materials in order</li>
          <li>
            Keep the plumber, protruding pipes, rebar, and other materials in an
            organised manner
          </li>
          <li>Clear the walkways from extension pipes</li>
        </List>
        <Typography variant="body1">
          One quick note: Instruct all your workers to follow these tips to keep
          the area well-organised and neat.
        </Typography>
        <Typography variant="h2" color="blue">
          #3 Follow safety signs and procedures
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/simple-construction-site-safety-rules/image-2.jpeg"
            alt="Follow safety signs and procedures"
            title="Follow safety signs and procedures"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Did you know construction site safety signs get displayed for
          delivering safety messages and health? Undermining the importance of
          safety and healthy sign on these sites may mean life loss or fatal
          injuries. Or it may make someone lose their job. So, it is vital to
          use different safety signs for different purposes. Follow the given
          pointers to learn more about them:
        </Typography>
        <ul className={s.textLists}>
          <li>
            <Typography variant="body1">
              <strong>Prohibition Signs</strong>
            </Typography>
            <Typography variant="body1">
              It’s usually at the entrance of the site. It’s an indication to
              let people know that you should not do something inside the
              construction premises. A few best examples are ‘stop,’‘no smoking,
              ‘or ‘no entry.’
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Warning Signs</strong>
            </Typography>
            <Typography variant="body1">
              The warning signs are the indications that make people aware of a
              hazard or danger. They come in solid yellow colour. And they
              usually include texts like high voltage, work overload, deep
              excavations, asbestos, etc.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Mandatory Signs</strong>
            </Typography>
            <Typography variant="body1">
              These signs are the opposite of the prohibition signs. Rather than
              asking you not to do something inside the location, they indicate
              what you must do. They usually have a solid blue circle
              surrounding the text. Some examples include ‘wear safety
              footwear,’‘wear hard hats,’ etc.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Safe Condition Signs</strong>
            </Typography>
            <Typography variant="body1">
              These signs are opposite to the warning signs. The best examples
              are first aid, fire exit, and more. The texts appear surrounded by
              solid green squares.
            </Typography>
          </li>
        </ul>
        <Typography variant="h2" color="blue">
          #4 Report Defects
        </Typography>
        <Typography variant="body1">
          Construction defects are quite common, although maximum defects are
          fairly inconsequential and minor. But dangerous ones may risk damage
          to either the property or employees. A construction defect may include
          the following:
        </Typography>
        <List className="pl-4">
          <li>
            A deficiency in the process that results from poor materials,
            design, or workmanship
          </li>
          <li>
            A deficiency leading to a failure in a structure designed during
            that project
          </li>
          <li>Failure causing damage to the property or person</li>
        </List>
        <Typography variant="body1">
          One should report any such defects immediately after getting familiar
          with it during the construction project.
        </Typography>
        <Typography variant="h2" color="blue">
          #5 Use the Serviced Equipment
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/simple-construction-site-safety-rules/image-3.jpeg"
            alt="Use the Serviced Equipment"
            title="Use the Serviced Equipment"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Not each construction tool meets the requirements of all jobs. So, use
          the serviced equipment that makes your job quicker, speedier, and
          safer. It is imperative to check the equipment’s condition and safety
          before using them.
        </Typography>
        <Typography variant="body1">
          One quick note: Using 110v equipment on the construction site is
          advisable. However, the 240v tools should only get used after
          authorisation from the management.
        </Typography>
        <Typography variant="h2" color="blue">
          #6 When In Doubt, Ask
        </Typography>
        <Typography variant="body1">
          Are you unaware of the best ways to manage safety during construction?
          Do you want to learn about the maintenance of any serviced equipment?
          Considering construction jobs are demanding and challenging always be
          sure of certain things.
        </Typography>
        <Typography variant="body1">
          Assuring yourself with the jobs that you perform at the site will
          prevent the employees and the public from hazards. And remember, it’s
          always better to be safe than be sorry. Refrain from mistakes because
          a minor blunder at the construction location may result in life loss.
          So, you must ask before performing the task whenever you are in doubt.
        </Typography>
        <Typography variant="body1">
          Construction workers may encounter multiple hazards at work every day.
          But to avoid them from experiencing machinery accidents or falling
          debris, it is important to raise awareness. Another excellent way to
          reach safety at construction site comes with SIGNAX services
          implementation.
        </Typography>
        <Typography variant="body1">
          SIGNAX team develops digital products to improve project management in
          the construction. With help of innovations and data, you can control
          all main indicators of the project right in your smartphone. You can
          be sure of current safety status of construction being remote from the
          site.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default SimpleConstructionSiteSafetyRules
